/*eslint-disable*/
import React from 'react';
import Slider from 'react-slick';

import signup from '../../assets/icons/signup.svg';
import choose from '../../assets/icons/choose.svg';
import checkout from '../../assets/icons/checkout.svg';
import cart from '../../assets/icons/cart.svg';

export default function Process() {
  const settings = {
    dots: false,
    arrows: false,
    className: 'center',
    infinite: false,
    slidesToShow: 4,
    variableWidth: false,
    autoplay: false,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <div>
      <div className="mt-24">
        <hr className="dotted w-full" />
        <div className="-mt-12">
          <div className="block">
            <Slider {...settings}>
              <div className="flex flex-col justify-between items-center space-y-4 mt-4 bg-custom-first">
                <div className="flex justify-center h-26">
                  <div className="w-14 h-14 p-2 bg-primary rounded-full">
                    <img className="signup" src={signup} alt="" />
                  </div>
                </div>
                <div className="text-center text-xl font-bold dark:text-white">Sign Up</div>
                <div className="flex justify-center">
                  <div className="text-black dark:text-white dark:bg-opacity-70 opacity-40 text-center max-w-52 mb-4">
                    <div className="">First, Sign up on</div>
                    <div className="">shopping Dapp</div>
                  </div>
                </div>
                <div className="flex justify-center">
                  <button
                    type="button"
                    className="dark:bg-primary dark:text-white border border-solid border-black py-2.5 px-6 rounded"
                  >
                    Sign Up
                  </button>
                </div>
              </div>
              <div className="flex flex-col justify-between items-center space-y-4 mt-4">
                <div className="flex justify-center h-26">
                  <div className="w-14 h-14 p-2 bg-primary rounded-full">
                    <img className="" src={choose} alt="choose" />
                  </div>
                </div>
                <div className="text-center text-xl font-bold dark:text-white">Choose Products</div>
                <div className="flex justify-center">
                  <div className="text-black dark:text-white dark:bg-opacity-70 opacity-40 text-center max-w-52 mb-4">
                    Second, Select the products that you like to buy
                  </div>
                </div>
                <div className="flex justify-center">
                  <button
                    type="button"
                    className="border border-solid border-black dark:border-white py-2.5 px-6 rounded"
                  >
                    Search product
                  </button>
                </div>
              </div>
              <div className="flex flex-col justify-between items-center space-y-4 mt-4">
                <div className="flex justify-center h-26">
                  <div className="w-14 h-14 p-2 bg-primary rounded-full">
                    <img className="" src={cart} alt="cart" />
                  </div>
                </div>
                <div className="text-center text-xl font-bold dark:text-white">Add to Cart</div>
                <div className="flex justify-center">
                  <div className="text-black dark:text-white dark:bg-opacity-70 opacity-40 text-center max-w-52 mb-4">
                    Third, add products into your cart
                  </div>
                </div>
                <div className="flex justify-center">
                  <button
                    type="button"
                    className="border border-solid border-black dark:border-white py-2.5 px-6 rounded"
                  >
                    Start
                  </button>
                </div>
              </div>
              <div className="flex flex-col items-center space-y-4 bg-custom-last">
                <div className="flex justify-center h-26 mb-8">
                  <img className="w-26" src={checkout} alt="checkout" />
                </div>
                <div className="text-center text-xl font-bold dark:text-white ">Check Out</div>
                <div className="flex justify-center">
                  <div className="text-black dark:text-white dark:bg-opacity-70 opacity-40 text-center max-w-52 mb-4">
                    Pay with your favorite cryptocurrency coin
                  </div>
                </div>
                <div className="flex justify-center">
                  <button
                    type="button"
                    className="border border-solid border-black dark:border-white py-2.5 px-6 rounded"
                  >
                    Start
                  </button>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
}
