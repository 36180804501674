import React from 'react';
import logo from '../../assets/icons/logo.svg';
import twitter from '../../assets/icons/twitter.svg';
import telegram from '../../assets/icons/telegram.svg';
import discord from '../../assets/icons/discord.svg';
import snake from '../../assets/icons/snake.svg';

function Footer() {
  return (
    <footer className="bg-dark text-white">
      <div
        className="flex flex-row justify-between items-center py-12 px-4 lg:px-32
                      lg:border-b border-solid border-gray"
      >
        <div className="flex flex-col space-y-4">
          <div className="flex items-center space-x-2 md:mr-20">
            <img className="w-8 pt-2" src={logo} alt="logo" />
            <div className="font-black text-2xl">Shopping</div>
          </div>
          <div className="opacity-50">© 2021Shopping</div>
          <div className="flex items-center space-x-4">
            <img src={twitter} alt="twitter" />
            <img src={discord} alt="discord" />
            <img src={telegram} alt="telegram" />
            <img src={snake} alt="snake" />
          </div>
        </div>
        <div className="flex flex-col space-y-2">
          <div className="">Project</div>
          <div className="opacity-50">About us</div>
          <div className="opacity-50">Team</div>
          <div className="opacity-50">Staking</div>
        </div>
        <div className="flex flex-col space-y-2">
          <div className="">Help</div>
          <div className="opacity-50">Contact</div>
          <div className="opacity-50">Trustpilot</div>
          <div className="opacity-50">Telegram</div>
        </div>
        <div className="hidden lg:flex flex-col space-y-6">
          <div className="">
            <div className="">Say up to day</div>
            <div className="opacity-70">Get the lastest updaites for shopping</div>
          </div>
          <div className="flex">
            <div className="relative flex h-12 w-full mr-4">
              <input
                type="text"
                className="border border-solid border-lighterGray bg-transparent
                           py-2 px-6 rounded-lg w-full h-full"
                placeholder="Email"
              />
            </div>
            <button type="button" className="bg-primary text-white py-3 px-2 rounded-lg">
              Subscribe
            </button>
          </div>
        </div>
      </div>
      <div className="lg:hidden flex flex-col items-center space-y-6 pb-12 border-b border-solid border-gray">
        <div className="">
          <div className="">Say up to day</div>
          <div className="opacity-70">Get the lastest updaites for shopping</div>
        </div>
        <div className="flex">
          <div className="relative flex h-12 w-full mr-4">
            <input
              type="text"
              className="border border-solid border-lighterGray bg-transparent
                           py-2 px-6 rounded-lg w-full h-full"
              placeholder="Email"
            />
          </div>
          <button type="button" className="bg-primary text-white py-3 px-2 rounded-lg">
            Subscribe
          </button>
        </div>
      </div>
      <div className="flex justify-between py-12 px-4 lg:px-32">
        <div className="">Shopping DAPP LTD</div>
        <div className="flex">
          <div className="opacity-50 mr-4">Business Mailing Address:</div>
          <div className="">Singapore, Golden House 3, 73889 Singapore.</div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
