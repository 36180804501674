/*eslint-disable*/
import React from 'react';
import watch from '../../assets/images/watch-white.png';
import increase from '../../assets/icons/increase.svg';

function FeatureCard({ wrapperClassName, dark }) {
  return (
    <div className="z-30">
      <div className={`rounded-lg shadow ${dark ? 'bg-black' : 'bg-white'}`}>
        <div className="relative">
          <div className="">
            <div className="flex flex-wrap lg:flex-nowrap space-x-4 p-10 pr-0">
              <div className="w-full lg:w-auto">
                <div className={`text-2xl ${dark ? 'text-white' : 'text-black'}`}>
                  {dark ? 'Bigest discounts' : 'Trending products'}
                </div>
                <div className="text-gray mt-4">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto eius eveniet,
                  facilis ipsam laborum laudantium magni quasi quia rerum unde.
                </div>
                <button
                  type="button"
                  className={`text-white py-3 px-4 rounded mt-4 ${
                    dark ? 'bg-primary' : 'bg-black'
                  }`}
                >
                  Explore
                </button>
              </div>
              <div className="relative w-full lg:w-auto">
                <img src={watch} alt="watch" className="absolute -bottom-10 right-0" />
                <div className="z-10 pr-20">
                  {dark ? (
                    <div className="relative bg-primary text-white text-4xl w-28 h-28 rounded-full">
                      <div className="absolute top-9 left-3">-10%</div>
                    </div>
                  ) : (
                    <div className="relative bg-orange text-white text-4xl w-28 h-28 rounded-full">
                      <img src={increase} alt="increase" className="absolute top-10 right-7" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeatureCard;
