import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import routes from './RouteMap';
import Home from '../pages/Home';
import NotFound from '../pages/NotFound';

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/" render={() => <Redirect to={routes.home} />} />
      <Route exact path={routes.home} component={Home} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default Routes;
