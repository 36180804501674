import React, { useState } from 'react';
import magnifier from '../../assets/icons/magnifier.svg';
import logo from '../../assets/icons/logo.svg';

function Header() {
  const [openMenu, setOpenMenu] = useState(false);

  const toggleHover = () => {
    setOpenMenu(!openMenu);
  };
  return (
    <header className="flex justify-between items-center py-4 px-4 lg:px-12">
      <div className="flex justify-between items-center w-full mr-6 max-w-4xl z-20">
        <div className="flex items-center space-x-2 mr-20">
          <div className="font-black text-2xl">Shopping</div>
          <img className="w-8 pt-2" src={logo} alt="logo" />
        </div>
        <div
          className="border border-solid border-lightGray
                        relative flex h-12 w-full placeholder-pl-none"
        >
          <img src={magnifier} alt="magnifier" className="p-3 pl-4" />
          <input
            type="text"
            className="py-2 rounded
            w-full h-full border-0 outline-none"
            placeholder="Search for anything on Amazon, eBay and Walmart or paste an Amazon ASIN"
          />
        </div>
      </div>
      <button
        type="button"
        className="lg:hidden z-20 text-xs rounded border-white border px-3 py-1"
        onClick={toggleHover}
      >
        {openMenu ? 'CLOSE' : 'OPEN'}
      </button>

      <nav
        className={`items-center z-10 transform lg:static text-center
          lg:translate-y-0 block absolute   left-0 top-0 bg-white 
          transition
          w-full pt-28
          pb-10
          lg:py-0
          lg:max-w-sm
          
          ${openMenu ? 'translate-y-0' : '-translate-y-full'}`}
      >
        <ul className="flex justify-between items-center w-full px-8 lg:px-0 flex-col lg:flex-row">
          <li className="">Browse</li>
          <li className="lg:border-r lg:border-solid lg:border-lighterGray lg:pr-6 py-2">
            How it works
          </li>
          <li className="">Crypto</li>
          <li className="mt-3 lg:mt-0">
            <button type="button" className="bg-primary text-white py-3 px-2 rounded">
              Connect
            </button>
          </li>
        </ul>
      </nav>
    </header>
  );
}

Header.propTypes = {};

Header.defaultProps = {};

export default Header;
