import React from 'react';
import watch from '../../assets/images/watch-white2.png';

function MiniProductCard({ wrapperClassName }) {
  return (
    <div className={wrapperClassName}>
      <div className="flex flex-col items-center space-y-2 py-4 px-16 rounded bg-white">
        <img className="w-24" src={watch} alt="watch" />
        <div className="text-22 font-bold">Product name</div>
        <div className="text-primary font-bold">$00,00</div>
      </div>
    </div>
  );
}

export default MiniProductCard;
