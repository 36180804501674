import React from 'react';
import avatar from '../../assets/images/avatar.jpg';
import watch from '../../assets/images/watch-black.png';
import logo1 from '../../assets/images/logo1.png';
import amazon from '../../assets/icons/amazon.svg';

function ProductCard({ wrapperClassName }) {
  return (
    <div className={wrapperClassName}>
      <div className="border border-solid border-lighterGray bg-white rounded shadow">
        <div className="py-4 px-6 border-b border-solid border-lightGray">
          <div className="flex justify-between items-center">
            <div className="flex space-x-4 items-center">
              <img className="w-10 rounded-full" src={avatar} alt="avatar" />
              <div className="text-lg font-bold">Denice E.</div>
            </div>
            <img className="mt-2" src={amazon} alt="amazon" />
          </div>
          <div className="flex justify-between items-center space-x-6 mt-4">
            <img className="" src={watch} alt="watch" />
            <div>
              <div className="text-lg font-bold">
                <div className="">Apple Watch Series</div>
                <div className="">3 - Spacegrey</div>
              </div>
              <div className="flex space-x-4">
                <div className="text-fadePrimary">$266</div>
                <div className="text-black opacity-50">$280</div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-between items-center py-2 px-6">
          <div className="text-fadePrimary font-bold">5% discount</div>
          <div className="flex justify-between items-center">
            <div className="mr-2 font-bold">Paid with</div>
            <img className="pt-2" src={logo1} alt="logo" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductCard;
