import React from 'react';
import ProductCard from './ProductCard';
import SwipeSlider from '../UI/SwipeSlider';

export default function ProductsSlider({ title }) {
  return (
    <div className="">
      {title && <h1 className="font-bold text-4xl mb-16 text-center">{title}</h1>}
      <div className="">
        <SwipeSlider>
          <ProductCard wrapperClassName="mx-3" />
          <ProductCard wrapperClassName="mx-3" />
          <ProductCard wrapperClassName="mx-3" />
          <ProductCard wrapperClassName="mx-3" />
          <ProductCard wrapperClassName="mx-3" />
          <ProductCard wrapperClassName="mx-3" />
          <ProductCard wrapperClassName="mx-3" />
          <ProductCard wrapperClassName="mx-3" />
          <ProductCard wrapperClassName="mx-3" />
          <ProductCard wrapperClassName="mx-3" />
        </SwipeSlider>
      </div>
    </div>
  );
}
