/*eslint-disable*/
import React from 'react';
import Layout from '../layouts/MainLayout/MainLayout';
import FeatureCard from '../components/common/FeatureCard';
import magnifier from '../assets/icons/magnifier.svg';
import curve from '../assets/images/curve.svg';
import ProductsMinimalSlider from '../components/common/ProductsMinimalSlider';
import ProductsSlider from '../components/common/ProductsSlider';
import Process from '../components/common/Process';
import ShopCategories from '../components/common/ShopCategories';
import Banner from '../components/common/Banner';

export default function Home() {
  return (
    <Layout wrapperClassName="">
      <div className="mt-8 w-full">
        <Banner />
        <div className="relative">
          <img className="hidden xl:block w-full absolute top-4" src={curve} alt="bg-curve" />
          <section className="overflow-hidden mx-auto z-10 pt-48 mt-16 products-slider">
            <ProductsSlider />
          </section>
        </div>
        <div className="container mt-10 lg:mt-48">
          <section className="mx-auto w-full max-w-4xl lg:max-w-7xl">
            <div className="flex flex-col items-center lg:flex-row lg:justify-center justify-between lg:space-x-4 space-y-4 lg:space-y-0 w-full">
              <FeatureCard dark />
              <FeatureCard />
            </div>
          </section>
        </div>
        <section className="container mx-auto mt-32 lg:mt-48">
          <h1 className="relative text-5xl font-bold text-center leading-16">
            Buy your first product <br />
            with one of your crypto’s
          </h1>
          <div className="flex justify-center mt-8 mx-auto">
            <div
              className="border border-solid border-lightGray rounded-bl rounded-tl rounded-tr-none  rounded-br-none 
                        relative flex h-12 w-full placeholder-pl-none max-w-2xl"
            >
              <img src={magnifier} alt="magnifier" className="p-3 pl-4" />
              <input
                type="text"
                className="py-2 rounded w-full h-full border-0 outline-none"
                placeholder="Search for anything on Amazon, eBay and Walmart or paste an Amazon ASIN"
              />
            </div>
            <div className="bg-black text-white rounded-r px-4 pt-3">search</div>
          </div>
        </section>
        <section className="mt-6 overflow-hidden">
          <ShopCategories />
        </section>
        <section className="container mt-20 lg:mt-40 overflow-hidden">
          <Process />
        </section>
        <section className="mx-auto mt-16 bg-lightBlue space-x-4 py-10 overflow-hidden">
          <ProductsMinimalSlider title="Featured products" />
          <ProductsMinimalSlider title="Newest Products" />
          <ProductsMinimalSlider title="Computer components" />
          <div className="mt-14 mb-16">
            <ProductsSlider title="Inspired by your browsing history" />
          </div>
        </section>
      </div>
    </Layout>
  );
}
