import React from 'react';
import Slider from 'react-slick';
import prevArrow from '../../assets/icons/prevArrow.svg';
import nextArrow from '../../assets/icons/nextArrow.svg';

function ArrowSlider({ children }) {
  const carouselRef = React.createRef();

  const settings = {
    dots: false,
    arrows: false,
    slidesToShow: 3,
    swipeToSlide: true,
    variableWidth: false,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const handlePrev = () => {
    carouselRef.current.innerSlider.slickPrev();
  };

  const handleNext = () => {
    carouselRef.current.innerSlider.slickNext();
  };

  return (
    <div className=" container">
      <div className="relative">
        <div
          onClick={handlePrev}
          className="text-lg font-bold absolute top-1/2
          transform -translate-y-1/2 left-0 z-30 cursor-pointer"
        >
          <img className="bg-primary p-1 rounded" src={prevArrow} alt="prevArrow" />
        </div>
        <div className="">
          <Slider {...settings} ref={carouselRef} draggable>
            {children}
          </Slider>
        </div>
        <div
          onClick={handleNext}
          className="text-lg font-bold absolute top-1/2
          transform -translate-y-1/2 right-0 z-30 cursor-pointer"
        >
          <img className="bg-primary p-1 rounded" src={nextArrow} alt="nextArrow" />
        </div>
      </div>
    </div>
  );
}

export default ArrowSlider;
